import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="Scrap Metal Trading">Introduction</a>
            </li>
            <li>
              <a href="#">Scrap Types</a>
            </li>
            <li>
              <a href="#">Scrap Details</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Place Your Order Today</h4>
          <p>
          Transform your metal scrap trading experience with unparalleled efficiency and expertise. Embrace the value of sustainable practices, the benefits of meticulous sorting, and the precision of our professional service. Partner with us today and embark on a journey of maximizing returns and minimizing waste. Discover the Future of Metal Scrap Trading.
          </p>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
