import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "@/components/section-title";
// import { FeatureTabData } from "@/data";
import featureTab01 from "@/images/hms1.png";
import featureTab02 from "@/images/LMS.png";
import featureTab03 from "@/images/Tyre Wire Scrap.jpeg";
import featureTab04 from "@/images/Metal Scrap.png";

const FeatureTabOne = () => {
  const [active, setActive] = useState(1);
  // const { sectionContent, posts } = FeatureTabData;

  const FeatureTabData = {
    sectionContent: {
      title: "Scrap Types",
      subTitle: "Explore our",
    },
    posts: [
      {
        title: "HMS1 Scrap",
        content:
          "Heavy Melting Steel (HMS1) refers to a grade of scrap metal characterized by its large and bulky steel pieces, typically sourced from demolished structures, industrial machinery, and heavy equipment. As a crucial raw material in the steel recycling industry, HMS1 consists of clean, uncoated, and non-contaminated steel, making it ideal for melting down and repurposing into new steel products. Its high-density and robust nature contribute to its value in the recycling process, facilitating the production of high-quality steel with minimal environmental impact.",
        image: featureTab01,
      },
      {
        title: "LMS Scrap",
        content:
          "Light Melting Steel (LMS) is a category of scrap metal that comprises smaller and lighter steel pieces, often originating from thinner gauge materials and smaller equipment. Unlike Heavy Melting Steel (HMS), LMS typically includes parts like discarded automotive components, appliances, and industrial scraps that are less bulky but still valuable. It is ideal for recycling due to its clean and relatively homogeneous composition, which makes it suitable for melting and refining into new steel products. LMS plays a key role in the steel recycling industry by providing a versatile and easily processed material that helps in producing high-quality steel efficiently.",
        image: featureTab02,
      },
      {
        title: "Tyre Wire Scrap",
        content:
          "Tyre wire scrap consists of steel wire extracted from used tyres, primarily from the steel belts and beads embedded in the tyre structure. This scrap is highly valuable in the recycling industry due to its high-quality steel content, which can be reclaimed and repurposed for various applications. Tyre wire scrap is processed to remove contaminants such as rubber and other residues, resulting in clean, recyclable steel wire that can be used in the production of new steel products or in other industrial applications. Its recycling helps reduce waste and supports the circular economy by repurposing materials that would otherwise contribute to landfill.",
        image: featureTab03,
      },
      {
        title: "Metal Scrap",
        content:
          "Metal scrap refers to discarded or leftover metal materials that can be reclaimed and recycled into new products. This category includes a wide range of metal types, such as ferrous metals (like steel and iron) and non-ferrous metals (such as aluminum, copper, and brass). Metal scrap is collected from various sources, including industrial processes, demolished buildings, discarded appliances, and vehicle parts. It is sorted, cleaned, and processed at recycling facilities, where it is melted down and reformed into new metal products. Recycling metal scrap conserves natural resources, reduces energy consumption, and minimizes environmental impact by diverting waste from landfills and reducing the need for virgin metal extraction.",
        image: featureTab04,
      },
    ],
  };

  return (
    <section id="why" className="majdoolchooseUs">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={FeatureTabData.sectionContent} />
          </Col>
        </Row>
        <div id="tabs">
          <Row>
            <Col lg={12}>
              <ul className="majdoolchooseUs_title">
                {FeatureTabData.posts.map(({ title }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {FeatureTabData.posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col lg={7}>
                      <div className="wh_choose">
                        <p>{post.content}</p>
                        {/* <ul>
                          {post.lists.map(({ item }, index) => (
                            <li key={index}>
                              <i className="fa fa-check-square"></i> {item}
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="chose_img">
                        <img src={post.image} alt={`chose_img-${index}`} />
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeatureTabOne;
