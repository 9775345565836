import React from "react";
import serviceS1 from "@/images/scrap1.png";
import serviceS2 from "@/images/s2.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <h2>Scrap Metal Trading</h2>
      <p>
      Palm Desert Trading involves acquiring and selling discarded or surplus metal materials sourced from various origins, such as industrial sites, construction projects, and household items. At Palm Desert Trading, we specialize in sorting, processing, and recycling these metals into valuable raw materials for manufacturing and construction. Our operations support the circular economy by conserving resources and reducing waste, while also offering economic benefits through revenue generation from recycled metals. By prioritizing sustainability and efficiency, Palm Desert Trading plays a key role in the metal recycling industry, contributing to environmental protection and resource management.
      </p>
      <img src={serviceS1} alt="" />
    </div>
  );
};

export default ServiceDetailsContent;
